.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 10px;
  background-color: lightgray;
  padding: 5px
}

.container > form {
  padding: 15px;
}

.inputsContainer {
  margin-bottom: 6px;
}

.errorMessage {
  margin-bottom: 16px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: var(--colorDanger);
}

.passwordCharacterFormatting {
  font-size: 12px;
}

.helpText {
  font-size: 14px;
  padding: 0 15px 5px 15px;
}

.bottomLogo > img {
  display: flex;
  height: 60px;
  margin: 0 auto 20px;
}

.termsLinkWrapper {
  padding: 0 0 0 15px;
}
