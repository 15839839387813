.container{
  margin: 0 0 3% 0;
  padding: 0 20px 0 20px;
  height: 25px;

  span {
    font-style: italic;
  }

  span:last-child{
    float: right;
  }

  span > i {
    font-weight: 100;
  }

  span > i > strong {
    font-weight: 800;
  }

  span > i > img{
    width: 15px;
  }
}