$bannerHeight: 115px;
$bannerColor: rgba(7, 51, 80, 0.9);
$bannerEndWidth: 50px;
%localFont {
  font-family: var(--secondaryFont);
  font-weight: 600;
  letter-spacing: 3px;
}

.container {
  width: 450px;
  height: 300px;
  background-color: grey;
  position: relative;
  cursor: pointer;
  margin-bottom: 20px;
  overflow: hidden;
  transform: translateZ(0);

  > * {
    backface-visibility: hidden;
    transform: translateZ(0);
  }
}

.background {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: gray;
  transform: scale(1);
  transition: transform .3s;
  
  &:hover {
    transform: scale(1.02);
  }
}

.bannerWrapper {
  width: 300px;
  height: $bannerHeight;
  background-color: $bannerColor;
  padding-left: 20px;
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}

.bannerEnd {
  height: 0;
  width: 0;
  position: absolute;
  top: 0;
  right: -$bannerEndWidth;
  border-bottom: $bannerHeight solid transparent;
  border-right: 0px solid transparent;
  border-left: $bannerEndWidth solid $bannerColor;
}

.bannerWrapperTextWrapper {
  width: 100%;
}

.title {
  text-transform: uppercase;
  font-style: italic;
  color: white;
  font-size: 40px;
  line-height: 40px;
  text-align: center;
  z-index: 1;
  position: relative;
  @extend %localFont;
}

.subTitle {
  color: var(--colorSecondaryBase);
  font-style: italic;
  font-size: 34px;
  line-height: 34px;
  text-align: right;
  padding-right: 30px;
  @extend %localFont;
  font-weight: 300;
}

.numberIndicatorWrapper {
  width: 60px;
  height: 60px;
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--colorSecondaryBase);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  transform: rotate(45deg) translateZ(0);
  pointer-events: none;

  >.text {
    font-size: 36px;
    color: white;
    transform: rotate(-45deg);

    &.smaller {
      font-size: 30px;
    }
  }
}

.iconWrapper {
  position: absolute;
  bottom: 15px;
  left: 20px;
  
  >svg {
    height: 40px;
    fill: white;
  }
}
