.modalBackgroundLayer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 0;
    width: 100%;
    height: 100vh;
}

.exitLayer {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100vh;
}

.modal {
    height: 85vh;
    width: 70%;
    background-color: #EEEEEE;
    position: absolute;
    bottom: 0;
    left: 15%;
    border-radius: 50px 50px 0 0;
    z-index: 1;
}

.header {
    width: 100%;
    height: 100px;
    border-bottom: 2px solid #cacaca;
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
}

.headerText {
    margin: 0;
}

.closeModal {
    background-color: transparent;
    font-size: 20px;
    padding: 0;
}

span:hover {
    color: #e3761c;
    cursor: pointer;
}

.headerContainer {
    height: 100px;
    display: flex;
    align-items: center;
}

.modalContent {
    height: calc(100% - 100px);
    padding: 30px 50px;
    overflow-y: auto;
}

.date {
    color: #628397;
    font-size: 15px;
    margin: 0;
}

.title {
    margin: 0;
}

@media only screen and (max-width: 700px) {   
    .modal {
        height: 85%;
        width: 90%;
        left: 5%;
        border-radius: 0;
        bottom: 0;
    }
    
    .header {
        height: 50px;
        font-size: 15px;
        padding: 25px;
    }
    
    .headerContainer {
        height: 50px;
    }
    
    .modalContent {
        height: calc(100% - 50px);
        padding: 20px 30px;
        overflow-y: auto;
    }
    
    .date {
        font-size: 10px;
        margin: 0;
    }
    
    .title {
        margin: 0;
        font-size: 20px;
    }

    .mobileParagraph {
        font-size: 12px;
    }
  }