.container {
  background-color: white;
  max-width: 400px;
  width: 100%;
  border: 5px solid lightgray;
}

@media only screen and (max-width: 550px) {
  .container {
    max-width: 350px;
  }
}

@media only screen and (max-width: 400px) {
  .container {
    max-width: 275px;
  }
}