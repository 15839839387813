.container {
  font-size: 14px;
  display: grid;
  grid-row-gap: 6px;
}

@media only screen and (min-width: 680px) {
  .container {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 10px;
  }
}