.container {
    font-size: 14px;
    display: grid;
    position: relative;
  }
  
  .documentsWrapper {
    height: 110px;
    overflow: auto;
  }
  
  .documentTitle {
    font-size: 14px;
    font-weight: 700;
  }
  @media only screen and (min-width: 680px) {
    .container {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 10px;
    }
  }