
.button {
  position: relative;
  padding-left: 27px;
  margin-bottom: 10px;
}

.button:after {
  content: '↵';
  font-size: 15px;
  margin-left: 5px;
  line-height: 0px;
  position: absolute;
  top: 14px;
  left: 3px;
}