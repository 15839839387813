.container {
  background-color: #073451;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.5);
  transition: 0.3s;
  width: 290px;
  display: flex;
  justify-content: center;
  align-self: flex-start;
  flex-direction: column;
  float: left;
  padding: 20px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

