.formContainer {
  background: white;
  padding: 25px;
  width:55vw;
  margin-bottom: 50px;
  fieldset{
    border: none;
  }
}

form {

}

.loadControl {
  display:flex;
  flex-wrap:wrap;
  margin-bottom:30px;
}

.loadControl .formControl {
  width: 33%;
}

.formControl {
  margin-bottom:20px;
}

.loadControl label {
  font-weight: bold;
}

label[name='Picks'], label[name='Drops'] {
  font-weight: bold;
}

.cityState {
  display:flex;
  flex-wrap:wrap;
}

.cityState .formControl {
  margin-right:2rem;
}

.pickDropContainer {
  display:flex;
}

.pickDropContainer .formControl {
  width: 50%;
}

.pickFieldArrayContainer {
  // border-bottom: 2px solid lightgray;
  padding-bottom: 20px;
}

.pickFieldArray {

}

.formControl span {
  color: red;
}

.error {
  color: red;
}

h5[name='picknumber'], h5[name='dropnumber'] {
  border-bottom: 1px solid lightgray;
}

input[name='pickDate'],
input[name='dropDate'],
input[name='equipment'],
input[type='text'],
textarea,
select  {
  display: block
}

textarea {
  width:100%;
  resize:auto;
  height: 75px
}

.buttonContainer {
  display: flex;
  justify-content: flex-start;
}

button {
  background-color: var(--colorSecondaryBase);
  color: white;
  border:none;
  border-radius: 5px;
}

.addBtn {
  width:25px;
  height:25px;
  font-size: 1rem;
  margin:5px;
}

.removeBtn {
  background-color: var(--colorPrimaryLight);
  width: 25px;
  height:25px;
  font-size: 1rem;
  margin:5px;
  font-weight: bold;
}

.formSubmitButtonContainer {
  display: flex;
  justify-content: space-evenly;
}

.formSubmitButtonContainer button {
  width:40%;
  height:40px;
  font-weight: bold;
  font-size: 1rem;
}

.cancelBtn {
  background-color: var(--colorPrimaryLight);
  width:30%;
  height:40px;
  font-weight: bold;
  font-size: 1rem;
  margin: 5px;
}

.voidBtn{
  background-color: var(--colorPrimaryLight);
  margin: 5px;
}
.voidBtn:hover{
  background-color: var(--colorDanger);
}

.voidModal{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.submitBtn {
  cursor: pointer;
  margin: 5px;
}

.disabledBtn {
  cursor: not-allowed
}

.fieldInline {
  display: flex;
}

@media only screen and (max-width: 1024px) { //IPAD PRO


  .pickDropContainer .formControl {
    width: 80%;
  }

  .formContainer {
    width:70vw;
  }
}

@media only screen and (max-width: 768px) { //IPAD
  .pickDropContainer {
    flex-direction: column;
  }

  .pickDropContainer .formControl {
    width:95%;
  }

  .pickDropContainer .formControl input {
    width:95%;
  }

  .loadControl .formControl {
      width:50%;
  }

  .loadControl .formControl input {
    width:50%;
  }

.cityState .formControl {
  margin-right:0;
  }
}

@media only screen and (max-width: 414px) { //IPHONE 6/7/8 PLUS
  .formContainer {
    width: 90vw;
  }
  .loadControl .formControl {
    width:100%;
}
}

@media only screen and (max-width: 375px) { //IPHONE X/6/7/8

}

@media only screen and (max-width: 320px) { //IPHONE 5/SE

}

