.container {
  display: flex;
  width: 100%;

  >.label {
    margin-right: 4px;
  }

  &.aligned {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    >.label {
      margin-right: 0;
    }

  }

  &.alignedRight {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    >.label {
      margin-right: 0;
    }

    >.text {
      text-align: right;
    }
  }
}

.text {
  margin-bottom: 17px;
}

.label {
  font-weight: 700;
}

.editInput {
  // margin-left: 5px;
  height: 25px;
  // width: 100%;
  // min-width: 200px;
  padding: 5px;

  width: 225px;
}
