
.addInvoiceForm {
  display: unset;

  label {
    display:block;
    font-weight: bold;
  }

  input {
    display:block;
    width: 100%;
    padding: 5px 10px;
    font-size: 100%;
    margin: 10px 0 20px;
  }

  button {
    padding: 7px 15px;
    background: var(--colorSecondaryDark);
    color: #fff;
    border: none;
    display: inline-block;
    margin-right: 10px;
    font-size: 100%;
  }
  
  .buttonCancel {
    background: var(--colorPrimaryBase);
  }

}

.errorText {
  color: red
}

