.nameWrapper{
  font-size: 22px;
}

.infoWrapper {
  padding-bottom: 7px;
}

.contactWrapper{
  font-size: 14px;
}

@media only screen and (min-width: 550px) {
  .contactWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}

