.bannerMoreInfo {
  transition: all 420ms;
  opacity: 0.5;
  margin-left: 10px;
  word-wrap: break-word;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  color: white;
}

.bannerMoreInfo:hover {
  opacity: 1;
}

.bannerMoreInfo a {
  color: white;
}

// Code for responsive arrows came from https://stackoverflow.com/a/27492688/1621272
.rightarrow {
  color: white;
  border: solid currentColor;
  border-width: 0 .2em .2em 0;
  display: inline-block;
  padding: .20em;
  transform:rotate(-45deg);
}

@media (max-width: 768px) {
  .bannerText {
    font-size: 14px;
  }
  .bannerMoreInfo {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .bannerText {
    font-size: 12px;
  }
  .bannerMoreInfo {
    font-size: 12px;
  }
}