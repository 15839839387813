.bannerContainer {
  width: 100%;
  height: 10vh; //Height is dynamic to the height of the viewport height (7 = 7/100 viewport height)
  min-height: 40px; //Set a min height for smaller screens
  max-height: 55px; //Set a max height for larger screens
  background-color: rgb(255, 71, 71);

  // Center elements
  display: flex;
  justify-content: center;
  align-items: center; 
}

.bannerText {
  margin-left: auto;
  max-width: 85%;
  color: white;
  word-wrap: break-word;
  text-align: center;
  font-weight: bold;
  font-size: 18px; 
}

.bannerDismiss {  
  margin-left: auto;
  margin-right:10px;
  transition: all 420ms;
  font-size: 24px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  opacity: 0.5;
}

.bannerDismiss:hover {
  opacity: 1;
}

.bannerMoreInfo {
  transition: all 420ms;
  opacity: 0.5;
  margin-left: 10px;
  word-wrap: break-word;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
}

.bannerMoreInfo:hover {
  opacity: 1;
}

.bannerMoreInfo a {
  color: white;
}

// Code for responsive arrows came from https://stackoverflow.com/a/27492688/1621272
.rightarrow {
  color: white;
  border: solid currentColor;
  border-width: 0 .2em .2em 0;
  display: inline-block;
  padding: .20em;
  transform:rotate(-45deg);
}

@media (max-width: 768px) {
  .bannerText {
    font-size: 14px;
  }
  .bannerMoreInfo {
    font-size: 14px;
  }
  .bannerDismiss {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .bannerText {
    font-size: 12px;
  }
  .bannerMoreInfo {
    font-size: 12px;
  }
  .bannerDismiss {
    font-size: 18px;
  }
}