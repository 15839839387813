
.addACHForm {
  display: unset;

  label {
    display:block;
    font-weight: bold;
  }

  input {
    display:block;
    width: 100%;
    padding: 5px 10px;
    font-size: 100%;
    margin: 10px 0 20px;
  }

}

.buttonAdd {
  padding: 7px 15px;
  background: var(--colorSecondaryDark);
  color: #fff;
  border: none;
  display: inline-block;
  margin-right: 10px;
  font-size: 100%;
}

.buttonAdd:disabled,
.buttonAdd[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}

.buttonCancel {
  padding: 7px 15px;
  color: #fff;
  border: none;
  display: inline-block;
  margin-right: 10px;
  font-size: 100%;
  background: var(--colorPrimaryBase);
}

.errorText {
  color: red
}

