.container {
  min-height: 100px;
  height: 100%;
  width: 100%;
  background-color: #f7f7f7;
  overflow: hidden;
  box-shadow: 2px 2px 10px -2px rgba(0,0,0,0.75);
  display: flex;
  flex-direction: column;
}

.titleBar {
  padding: 6px 10px;
  background-color: var(--colorPrimaryBase);
  min-height: 28px;
  flex-shrink: 0;
}

.titleBarNotes {
  padding: 6px 10px;
  background-color: var(--colorSecondaryBase);
  min-height: 28px;
  flex-shrink: 0;
}

.title {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: white;
}

.contentWrapper {
  height: 100%;
  flex: 1;

  &.padded {
    padding: 10px;
  }
}

.permMessage {
  text-align: center;
}
