.loadRequestWrap {
  position: relative;
  margin-left: 15px;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 3px solid white;
  margin: auto;
  cursor: pointer;
  }

.loadRequest {
  color: white;
  background: none;
  outline: none;
  border: none;
  font-size: 16px;
  position: relative;
  line-height: 36px;
  white-space: nowrap;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &:hover {
    transform: scale(1.2);
    color: var(--colorSecondaryBase);
    .arrow {
      border-top: 3px solid #c97932;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }
  }
}


.loadRequestDropdown {
  margin: 5px;
  padding: 10px 0;
  text-align: center;
  position: absolute;
  width: 140%;
  top: 34px;
  left: -20px;
  background: var(--colorPrimaryBase);
  display: none;
  font-size: 16px;

  &.open {
    animation: fadeIn ease .3s;
    border-radius: 10px;
    display: block;
  }

  @keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
    }

  >li {
    display: flex;
    list-style: none;
    padding: 0;
    margin-top: 5px;
  }

  a {
    color: white;
    width: 100%;
  }
}

.ar {
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
}
