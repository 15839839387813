.label {
  font-size: 10px;
}

.input {
  padding-left: 15px;
}

.AdjustPaymentWrapper {
  display: flex;
  align-items: center;
  margin: 0px;
}

.inputWrap {
  position: relative;
  &:after {
    content: '$';
    position: absolute;
    top: 6px;
    left: 6px;
  }
}

.inputWrap div {
  display: flex;
  align-items: center;
}

.error:enabled {
  background-color: var(--colorDanger);
  color: var(--colorLightWhite);
}

input.checkbox {
  transform: scale(1.3);
  width: 30px;
  cursor: pointer;
}


@media only screen and (max-width: 700px) {
  input.checkbox {
    transform: scale(2);
    display: flex;
    align-items: center;
    width: 30px;
    cursor: pointer;  
  }
}