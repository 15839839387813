.container{
  display: inline-block;
  margin: 10px;
  font-size: 0.95em;
  span {
    text-decoration: none;
    margin: 5px;
    padding-left: 8px;
    cursor: pointer;
    color: #e3761c;
  }
}



