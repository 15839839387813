.container{
  max-height: 200px;
  overflow-x: scroll;
}

.noteContainer {
  font-size: 14px;
  margin-bottom: 10px;
}

.seeMoreButton {
  color: var(--colorSecondaryLight);
  text-decoration: underline;
  border: none;
  background-color: inherit;
  font-size: 12px;
  cursor: pointer;
}

.seeMoreButton:hover {
  color: blue
}