.container {
  padding: 10px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: 20px;
  background-color: white;
}

.amountWrapper {
  font-size: 15px;
}

.detailsWrap {
  font-size: 15px;
  margin-bottom: 0px;
}

.summaryWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.startDetails,
.endDetails {
  font-size: 12px;
}

.buttonWrapper {
  display: grid;
  align-items: center;
}

.detailsLabel {
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  font-size: 15px;
}

@media screen and (max-width: 720px) {
  .summaryWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
  }

  .container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 420px) {
  .container {
    grid-template-columns: 1fr;
  }
}
