.container {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.disabledContainer {
  opacity: 0.6;
  pointer-events: none;
}

.userInfoContainer {
  width: 100%;
  flex-shrink: 0;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
  grid-template-areas: "userInfo";
}

.permissionsContainer {
  width: 100%;
  flex-shrink: 0;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
  grid-template-areas: "permissions";
}

.actionsContainer {
  width: 100%;
  flex-shrink: 0;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
  grid-template-areas: "actions";
}

.topRightButtons {
  float: right;
  display: inline;
  margin-left: 10px;
}

.bottomRightButtons {
  float: right;
  display: inline;
  width: auto;
}

.userInfo {
  grid-area: userInfo;
}

.permissions {
  grid-area: permissions;
}

.actions {
  grid-area: actions;
}

.nameWrapper {
  font-size: 22px;
  display: inline;
}

.infoWrapper {
  padding-bottom: 7px;
}

.userInfoWrapper {
  font-size: 16px;
}

.permissionsWrapper {
  font-size: 16px;
}

@media only screen and (min-width: 550px) {
  .userInfoWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }

  .permissionsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;
  }
}

.heading {
  margin: 0;
  margin: 0 20px 10px 0;
  color: black;
  border-right: 1px solid #ccc;
  padding-right: 20px;
  line-height: 1.2;
  font-size: 22px;
}

.light > .heading {
  color: white;
}

.headingContainer {
  display: inline-flex;
  align-items: center;
  padding: 25px 0 15px;
}

.link {
  border: 1px solid var(--colorSecondaryBase);
  border-radius: 20px;
  color: var(--colorSecondaryBase);
  padding: 5px 20px 5px 10px;
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  position: relative;
}

.light > .link {
  border: 1px solid var(--colorPrimaryBase);
  color: var(--colorPrimaryBase);
}

.link:after {
  content: "›";
  font-size: 21px;
  margin-left: 5px;
  line-height: 0px;
  position: absolute;
  top: 8px;
  right: 9px;
}

.link:hover {
  background: var(--colorFormInputFontColor);
  color: var(--colorLightWhite);
}

@media only screen and (max-width: 700px) {
  .headingContainer {
    display: block;
  }

  .heading {
    border-right: none;
    font-size: 26px;
  }

  .link {
    float: right;
  }
}

.buttons {
  display: inline;
}

.buttonDisable {
  display: inline;
}

.buttonDelete {
  display: inline;
  margin-left: 10px;
}

.permCheckbox {
  max-width: 100px;
  width: auto;
  margin-right: 0;
}
