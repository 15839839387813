$arrowHeight: 8px;
$arrowWidth: 5px;

.button {
  text-align: left;
  background-color: rgb(204, 204, 204);
  color: white;
  font-weight: 700;
  border-radius: 3px;
  padding-right: 24px;
  position: relative;
  transition: background-color .2s;
  margin-bottom: 5px;
  margin-right: 5px;

  &:after {
    content: '';
    width: 0; 
    height: 0; 
    border-left: $arrowWidth solid transparent;
    border-right: $arrowWidth solid transparent;
    border-bottom: $arrowHeight solid white;
    position: absolute;
    top: 50%;
    right: 8px;
    margin-top: calc($arrowHeight / 2) * -1;
    transition: transform .3s, opacity .2s;
    opacity: 0;
  }

  &.activeUp {
    background-color: var(--colorPrimaryBase);

    &:after {
      opacity: 100;
    }
  }

  &.activeDown {
    background-color: var(--colorPrimaryBase);

    &:after {
      opacity: 100;
      transform: rotateX(180deg)
    }
  }
}