.container {
  top: 0;
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: white;
  border-bottom: 1px solid var(--colorPrimaryBase);
  background-color: white;
  padding: 2px 5px;
  text-align: center;
  z-index: 2;
}

.textWrapper {
  margin-right: 5px;
}

.arrowsWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}