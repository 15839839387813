.container {
  font-size: 14px;
  overflow-x: scroll;
  max-height: 225px;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 6px 10px;
  position: relative;
  margin: 5px 0 15px;
}

.seeMoreButton {
  color: var(--colorPrimaryBase);
  text-decoration: underline;
  border: none;
  background-color: inherit;
  font-size: 12px;
  cursor: pointer;
}