//bring in the normalizer stylesheet to base styles
@import './_normalize.scss';

@import url('https://fonts.googleapis.com/css?family=Open+Sans:300i,400,400i,700,700i&display=swap');
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,600,600i,700,700i&display=swap');

:root {
  --colorPrimaryBase: #073451;
  --colorPrimaryLight: #214E6B;
  --colorPrimaryDark: #001B38;
  --colorPrimaryFaded: #628397;
  --colorPrimaryTrans: rgba(7, 51, 80, 0.5);
  --colorSecondaryBase: #e3761c;
  --colorSecondaryLight: #FD9036;
  --colorSecondaryDark: #CA5D03;
  --colorSecondarySuperDark: rgb(9, 18, 28);
  --colorSecondaryTrans: rgba(227, 118, 28, 0.5);
  --colorFormInputPlaceholder: rgba(44, 44, 44, .5);
  --colorFormInputFontColor: #474747;
  --colorFormInputBorder: #656565;
  --colorDanger: #ee2727;
  --colorWhite: rgb(199, 199, 199);
  --colorLightWhite: rgb(250, 250, 250);
  --baseFont: 'Open Sans', sans-serif;
  --secondaryFont: 'Montserrat', sans-serif;
}

* {
  box-sizing: border-box;
  font-family: var(--baseFont);
  color: black;
  font-weight: 400;
  text-rendering: optimizelegibility;
  line-height: 1.5;
}