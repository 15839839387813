.emailChipsWrapper {
  background-color: white;
  width: 100%;
  padding: 2rem;
  box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
  border-radius: 0.3rem;
  input {
    width: 100%;
    height: 45px;
    padding: 0 1rem;
    margin-top: 1rem;
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
  }

  input:focus {
    border-color: cornflowerblue;
    outline: none;
  }

  input.hasError {
    border-color: tomato;
  }
}

.error {
  margin: 0;
  font-size: 90%;
  color: tomato;
}

.tagItem {
  background-color: #d4d5d6;
  font-size: 14px;
  border-radius: 30px;
  height: 30px;
  padding: 0 4px 0 1rem;
  display: inline-flex;
  align-items: center;
  margin: 0 0.3rem 0.3rem 0;

  .deleteButton {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    color: black;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}


