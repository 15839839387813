.container {
  padding: 5px 10px;
  overflow: auto;
  min-height: 100%;
}

.headerWrapper {
  display: flex;
  justify-content: space-between;

  >h2 {
    margin: 0;
    font-size: 20px;
    color: white;
  }

  >button {
    color: var(--colorSecondaryBase);
  }
}

.button {
  background: var(--colorSecondaryBase);
  margin: 12px 0;
}

form {
  display: flex;
  flex-direction: column;
}
