
.unsubConfirmFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: white;
  width: 20%;
  height: 10%;
  align-self: center;
}
.unsubTitle {
  margin: 5px;
}

.unsubConfirmForm {
  display: flex;
}

.unsubOverlay {
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  border-radius: 10px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
  .unsubConfirmFormContainer {
    height: 15%;
    width: 60%;
  }
}

.btn {
  background-color: rgba(11, 42, 74, 0);
  color: #fff;
  border: none;
  font-size: 16px;
  line-height: 1;
  padding: 10px 15px;
  -webkit-appearance: button;
  border-radius: 3px;
  transition: background-color .3s;
  margin-right: 10px;
  cursor: pointer;
}

.btn_submit {
  background-color: rgb(132, 171, 78);
  box-shadow: 2px 3px #888888;

}

.btn_submit:hover {
    background-color: rgb(148, 191, 84);
}

.btn_unsubscribe {
  background-color: rgb(190, 68, 64);
  box-shadow: 2px 3px #888888;

}

.btn_unsubscribe:hover {
  background-color: rgb(206, 75, 71);
}
