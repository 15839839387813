.container {
  background-color: white;
  padding: 50px;
  max-width: 1200px;
  margin: 0 auto;
}

#policyContent {
  margin-top: 50px;
  margin-bottom: 50px;

  >h1 {
    color: black;
  }

  >h2 {
    color: black;
  }

  >strong {
    color: black;
  }

  >P {
    font-size: 15px;
    color: black;
  }
}

