.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
}

.bgDiv {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.headerWrapper {
  flex-shrink: 0;
  z-index: 1;
  //make the header sticky
  // position: sticky;
  // top:0;
}

.pageWrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.content {
  flex-grow:1;
  position: relative;
  min-height: 400px;
}

.innerContent {
  height: 1000px;
  display: flex;
  flex-direction: column;
  //not really necessary but it is nice to keep the option open for children to be centered horizontally
  align-items: center;
}

.loading {
  filter: blur(4px);
}
