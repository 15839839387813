.container {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.disclaimerHouse {
  background: white;
  // border-radius: 10px;
  padding: 30px;
  width: 75%;

}

@media only screen and (max-width: 768px) {
.disclaimerHouse {
  width: 90%;
}
}