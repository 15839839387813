.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  transform: translateZ(0);
}

.headerWrapper {
  display: flex;
  justify-content: space-between;
  flex-shrink: 0;
}

.contentWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  position: relative;
}

.sortersContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
  width: 170px;
  border-right: 1px solid var(--colorPrimaryBase);
  background-color: var(--colorPrimaryBase);
  position: absolute;
  height: 100%;
  top: 0;
  left: -170px;
  transition: left .3s;
  z-index: 1;
  padding-bottom: 20px;

  label {
    color: white;
  }

  &.open {
    left: 0;
  }

  @media only screen and (min-width: 1100px) {
    position: static;
  }
}

.listWrapper {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  background-color: rgb(119, 119, 119);
  @media only screen and (max-width: 420px) {
    display: flex;
    flex-direction: column;
  }
}

.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, .2);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.expandFilterButton {
  height: 16px;
  width: 80px;
  font-size: 12px;
  line-height: 1;
}

.headerButton {
  margin: 10px;
}
