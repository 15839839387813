.container {
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  align-items: center;
}

.invoiceTotal {
  font-weight: normal;
  color: #000;
}

.invoiceTotalText {
  margin-right: 5px;
  font-weight: bold;
  width: 220px;
}

.addInvoice {
  margin-right: 10px
}

.payNowButtonDisabled {
  background-color: gray !important; //Need to set to important otherwise IEL UI lib styling overrides this color
  color: #fff;
  border: none;
  font-size: 16px;
  line-height: 1;
  padding: 10px 15px;
  -webkit-appearance: button;
  border-radius: 3px;
  transition: background-color .3s;
  margin-right: 10px;
  cursor: wait;
}
