.container{
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.9);
  transition: 0.3s;
  padding: 30px;
  margin-bottom: 30px;
  h2, h3 {
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
  }
  i {
    font-size: 1em;
    font-style: italic;
  }
  img {
    width: 100%;
    margin: 10px 0 10px 0;
  }
  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

}
