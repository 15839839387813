.modalBackgroundLayer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.modal {
    height: 50vh;
    width: 70vw;
    max-width: 400px;
    max-height: 350px;
    background-color: #eeeeee;
    position: relative;
    z-index: 3;
}

.exitLayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.header {
    height: 40px;
    background: #073451;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerText {
    color: white;
    margin: 0;
    font-size: 20px;
    text-align: center;
}

.closeModal {
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    position: absolute;
    right: 10px;
}

.description {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
}

.question {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
}

.buttons {
    display: inline;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
}

.button {
    display: inline;
    margin-left: 10px;
}

span {
    color: white;
}

span:hover {
    color: #e3761c;
    cursor: pointer;
}
