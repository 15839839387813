.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 20px;
}

.layoutWrapper {
  flex: 1;
  width: 100%;
}

.backButton {
  margin: 5px;
  display: flex;
}

.button {
  position: relative;
  padding-left: 10px;
  margin-bottom: 10px;
  margin-left: 5px;;
}

.loading {
  margin: 0px;
  font-size: 16px;
  margin-left: 5px;
  // font-weight: 600;
  color: var(--colorSecondaryBase);
}

.loading:after {
  content: '';
  animation: dots 1s linear infinite
}

@keyframes dots {
  0%, 20% {
    content: ' .'
    }
  40% {
    content: ' . .'
    }
  60% {
    content: ' . . .'
    }
  80%, 100% {
    content: ''
    }
    }