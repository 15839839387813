.container {
  background-color:white;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  padding: 5px 10px;
}
.totalLine {
  text-decoration: overline;
  text-decoration-color: black;
}

.container:nth-child(odd){
  background-color: rgb(218, 218, 218);
}

@media only screen and (max-width: 550px) {
  .container {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .container {
    grid-template-columns: 1fr 1fr;
  }
}
