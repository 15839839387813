.avatarWrap {
  position: relative;
  margin-left: 20px;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 3px solid #c97932;
  margin-left: 40px;
  cursor: pointer;
}

.avatar {
  color: var(--colorSecondaryLight);
  background: none;
  border: none;
  outline: none;
  font-size: 14px;
  font-style: italic;
  padding-left: 37px;
  position: relative;
  line-height: 36px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.2);
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }

  &:after {
    content: "";
    background: url('/assets/svg/icon_avatar.svg') no-repeat center;
    height: 32px;
    width: 32px;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.avatarDropDown {
  margin: 0;
  padding: 15px;
  text-align: left;
  position: absolute;
  top: 34px;
  background: var(--colorPrimaryBase);
  display: none;

  &.open {
    animation: fadeIn ease 0.3s;
    border-radius: 10px;
    display: block;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  > li {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    white-space: nowrap;
  }

  a {
    color: white;
  }
}

.payment {
  margin-bottom: 10px;
}
