.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 0 10px 10px 10px;
}

.mainContainer {
  margin-left: 10px;
}
