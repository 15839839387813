.modalBackgroundLayer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.exitLayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.modal {
    height: 480px;
    width: 600px;
    background-color: #EEEEEE;
    position: relative;
    z-index: 3;
}

.header {
    height: 40px;
    background: #073451;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerText {
    color: white;
    margin: 0;
    font-size: 20px;
    text-align: center;
}

.closeModal {
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    position: absolute;
    right: 10px;
}

span {
    color: white;
}

span:hover {
    color: #e3761c;
    cursor: pointer;
}