.link {
  white-space: nowrap;
  text-overflow: ellipsis;
  //width: 200px;
  overflow: hidden;
  margin: 0;
  display: grid;
  grid-template-columns: auto;
  grid-column-gap: 10px;
  text-align: left;   
}