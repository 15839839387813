%inputStyles {
  outline: 0;
  margin: 0;
  font-size: 12px;
  padding: 2px 6px;
  border-radius: 3px;
  border: 1px solid var(--colorPrimaryBase);
}

.container {
  margin-bottom: 4px;
  display: flex;
  flex-direction: column;
}

.label {
  font-size: 14px;
  line-height: 20px;
  display: block;
  color: white;
}

.input {
  @extend %inputStyles;
  width: 100%;
}

.valueBox {
  @extend %inputStyles;
  background-color: var(--colorPrimaryBase);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

input.checkbox {
  transform: scale(1.3);
  width: 30px;
  cursor: pointer;
}


@media only screen and (max-width: 700px) {
  input.checkbox {
    transform: scale(2);
    display: flex;
    align-items: center;
    width: 30px;
    cursor: pointer;  
  }
}

.closeButton {
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: #fff;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 2px;
    top: 6px;
    left: 2px;
    background-color: var(--colorPrimaryBase);
    pointer-events: none;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}
