.container {
  display: flex;

  >.label {
    margin-right: 4px;
  }

  &.aligned {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    >.label {
      margin-right: 0;
    }

  }

  &.alignedRight {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

    >.label {
      margin-right: 0;
    }

    >.text {
      text-align: right;
    }
  }
}

.label {
  font-weight: 700;
}
