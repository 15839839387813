.modalWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal {
  background: var(--colorLightWhite);
  min-width: 400px;
  max-width: 600px;
  max-height: 80%;
  position: absolute;
  left: 50%;
  top: 50%;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 1px 1px 5px #ccc;
  z-index:100;
  transform: translate(-50%, -50%);

  @media screen and (max-width: 420px) {
    min-width: 300px;
    max-width: 90%;
  }

  @media screen and (max-width: 1100px) {
    max-width: 90%;
    max-height: 80%;
  }
}
