.container {
  font-size: 14px;
}

.totalLine {
  text-decoration: overline;
  text-decoration-color: black;
}


@media only screen and (min-width: 550px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
  }
}

