footer {
  position: relative;
}

.footerLinksContainer {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
}

.footerLogo {
  width: 70px;
  height: auto;
  display: inline-block;
  vertical-align: sub;
}

.footerLinks {
  min-height: 50px;
  background: black;
  width: 100%;
  padding: 10px 0;
  
  nav {
    display: inline-block;
  }

  ul {
    margin: 0 0 0 -15px;
    padding: 0;
  }

  li {
    margin: 0;
    padding: 0 15px;
    line-height: 1;
    display: inline-block;
    color: #fff;
    border-right: 1px solid white;
    font-size: 14px;
  }

  a {
    color: white;
    text-decoration: none;
    line-height: 1;
  }

  a:visited {
    color: white;
  }

  li:last-of-type, li:first-of-type {
    border-right: none;
  }

  #version, #version:visited{
    font-size: 12px;
    color:#696969;
  }

  #version:hover {
    cursor: pointer;
  }
}



@media only screen and (max-width: 1200px) {
  .footerLinksContainer {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 460px) {
  .footerLinks li {
    width: 50%;
    text-align: center;
    border: none;
    padding: 10px 0;
  }
}