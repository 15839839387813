.modalWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal {
  background: var(--colorLightWhite);
  width: 40%;
  height: 600px;
  position: sticky;
  left: calc(50% - 250px);
  top: calc(50% - 500px);
  box-shadow: 3px 5px 5px black;
  z-index:100;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .modalImageContainer {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: -100;
    img{
      width: 100%;
    }
  }

}

@media only screen and (max-width: 1100px) {
  .modal {
    height: 40vw;
    width: 40vw;
  }

}

@media only screen and (max-width: 1000px) {
  .modal {
    height: 45vw;
    width: 45vw;
  }
}

@media only screen and (max-width: 900px) {
  .modal {
    position: relative;
    top: calc(50% - 300px);
    height: 110%;
    width: 55%;
    .modalImageContainer {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: -100;

      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 700px) {
  .modal {
    height: 130%;
    width: 70%;
    left: 15%;
    .modalImageContainer {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: -100;

      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .modal {
    height: 70%;
    width: 70%;
    left: 15%;
    .modalImageContainer {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      z-index: -100;

      img {
        width: 100%;
      }
    }
  }
}

.btn {
  background-color: rgba(11, 42, 74, 0);
  color: #fff;
  border: none;
  font-size: 16px;
  line-height: 1;
  padding: 10px 15px;
  -webkit-appearance: button;
  border-radius: 3px;
  transition: background-color .3s;
  margin-right: 10px;
  cursor: pointer;
}

.btn_submit {
  background-color: rgb(132, 171, 78);
  box-shadow: 2px 3px #888888;

}

.btn_submit:hover {
    background-color: rgb(148, 191, 84);
}

.btn_unsubscribe {
  background-color: rgb(190, 68, 64);
  box-shadow: 2px 3px #888888;

}

.btn_unsubscribe:hover {
  background-color: rgb(206, 75, 71);
}


.loadNotificationContainer {
  display: flex;
  justify-content: flex-end;
}

.loadNotificationTitle {
  text-align: center;
  display: flex;
  align-items: center;
}

.loadNotificationDescription {
  display: flex;
  align-items: center;
}

.emailCCSubmit {
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
}

.emailInput {
  margin-bottom: 10px;
  height: 50%;
  width: 100%;
  outline: none;
}

.emailInputLabel {
  display: flex;
}

textarea {
  resize: none;
  outline: none;
  width: 70%;
  height: 30%;
}

textarea::placeholder {
  font-size: 12px;
}

.buttonContainer {
  display: flex;
  margin: 10px;
}

.error {
  font-size: 12px;
  color: red;
}

.UpdateModalContainer {
  position: absolute;
  right: 0;
  top: 0;
}

@media only screen and (max-width: 400px) {
  .buttonContainer {
    display: flex;
    flex-direction: row;
  }

  .btn {
    font-size: 10px;
  }
  textarea {
    height: 40%;
  }
}
