.container {
  display: grid;
  font-size: 14px;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 26px;
  background-color: white;
  padding: 2px 5px;
  text-align: center;

  &.highlighted {
    background-color: rgb(243, 243, 243);
  }
}

// TODO: This needs to be fixed for mobile?
.cellCustomerAdmin {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 35px;
  background-color: white;
  padding: 2px 5px;
  text-align: center;

  &.highlighted {
    background-color: rgb(243, 243, 243);
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
}

.notificationIcon svg * {
  color: var(--colorSecondaryLight);
  cursor: pointer;

}

[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;

}
[data-title]:after {
  content: attr(data-title);
  background-color: white;
  color: #111;
  font-size: 100%;
  position: absolute;
  padding: 1px 5px 2px 5px;
  border-radius: 7.5px;
  left: 100%;
  height: auto;
  width: 150px;
  box-shadow: 1px 1px 3px #222222;
  opacity: 0;
  border: 1px solid #111111;
  z-index: 999999;
  visibility: hidden;
}
[data-title] {
  position: relative;
}

.statusButton{
  cursor: pointer;
  min-width: 100px;
  font-weight: bolder;
}

.nonEditableStatusButton{
  cursor: pointer;
  min-width: 100px;
  font-weight: bolder;
  background-color: var(--colorPrimaryBase);
}
