.companyName {
  display: inline;
  font-size: 20px;
  color: var(--colorWhite);
  font-style: italic;
}

@media only screen and (max-width: 700px) {
  .companyName {
    display: inline;
    font-size: 16px;
  }
}

.dropdownButton {
  display: flex;
  align-items: center;
}

.fontAwesomeActive:hover {
  cursor: pointer;
}

.fontAwesomeInactive svg * {
  color: gray;
  margin-left: 1rem;
}

.fontAwesomeActive svg * {
  color: green;
  margin-left: 1rem;
}
