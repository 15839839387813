.container {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  justify-items: center;
  padding-bottom: 20px;
  
  &.row {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width: 720px) {
  .container {
      grid-template-columns: 1fr;

      &.row {
        grid-template-columns: 1fr;
      }
  }
}