$fontSize: 14px;
$lineHeight: 1;

.label {
  font-weight: 700;
  font-size: $fontSize;
  line-height: $lineHeight;
}

.field {
  font-size: $fontSize;
  line-height: $lineHeight;
}