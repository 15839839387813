.container {
  max-width: 1200px;
  width: 1000px;
  margin:0 auto;
  padding: 20px;
  display: flex;
}

strong {
  color: black;
}
