.modalBackgroundLayer {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.exitLayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
}

.modal {
    height: auto;
    width: 70vw;
    max-width: 400px;
    max-height: 425px;
    background-color: #eeeeee;
    position: relative;
    z-index: 3;
}

.header {
    height: 40px;
    background: #073451;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headerText {
    color: white;
    margin: 0;
    font-size: 20px;
    text-align: center;
}

.closeModal {
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    position: absolute;
    right: 10px;
}

span {
    color: white;
}

span:hover {
    color: #e3761c;
    cursor: pointer;
}

.body {
    margin: 10px auto 10px auto;
    width: 85%;
    text-align: left;
}

.btnSubmitFeedback {
    margin: 10px auto 10px auto;
    cursor: pointer;
}

.label {
    margin-top: 10px;
    margin-bottom: 5px;
}

select {
    width: 100%;
    color: var(--colorFormInputFontColor);
    border: 1px solid var(--colorFormInputBorder);
    border-radius: 3px;
    transition: border 0.3s;
    box-shadow: inset 0 0 0 0 transparent;
    outline: 0;
    background-color: white;
    font-size: 16px;
    height: 34px;

    &,
    > * {
        cursor: pointer;
        box-sizing: border-box;
    }

    font-family: inherit;
    line-height: 1.15;
    margin: 0;
    text-transform: none;
    padding: 5px 5px;
    user-select: none;

    > * {
        font-size: 16px;
        line-height: 22px;
    }

    &.disabled {
        background-color: var(--colorFormInputDisabledBackground);
    }
}

textarea {
    box-sizing: border-box;
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: auto;
    width: 100%;
    height: 100px;
    resize: none;
    padding: 5px 10px;
    color: var(--colorFormInputFontColor);
    border: 1px solid var(--colorFormInputBorder);
    border-radius: 3px;
    transition: border 0.3s;
    box-shadow: inset 0 0 0 0 transparent;
    outline: 0;
    background-color: white;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.charactersRemaining {
    margin-top: 5px;
    margin-right: 0;
    margin-left: auto;
    font-size: 12px;
    color: grey;
}

.charactersRemainingRed {
    font-weight: bold;
    margin-top: 5px;
    margin-right: 0;
    margin-left: auto;
    font-size: 12px;
    color: red;
}

.validateError {
    margin-top: 5px;
    margin-right: 0;
    margin-left: auto;
    font-size: 13px;
    color: red;
}

.submitStatus {
    z-index: 5;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.success {
    text-align: center;
    font-size: 16px;
    color: white;
}
