.searchInput {
 display:flex;
}

.awesomeIcon {
 background: orange;
 padding: 0px 5px;
}

.autocompleteSearch {
 background-color: yellow;
}

.searchField {
 border: 1px solid black;
}

.searchInput .awesomeIcon svg path {
 color:white
}
