.content {
  flex-grow:1;
  position: relative;
}

.innerContent {
  height: 65vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.padded {
    padding-bottom: 40px;
  }
}