.container {
  display: grid;
  justify-items: center;
  align-items: center;
  padding: 0 20px 100px 20px;
  background: var(--colorSecondarySuperDark) url('/assets/images/LOGIN_BACKGROUND_Nexus.jpg') no-repeat center / cover;
  min-height: calc(100% - 50px);
}

.svg > img {
  height: 165px;
  margin: 0 0 0 40px;
}

.welcome > p {
  color: white;
  font-size: 35px;
  font-family: var(--baseFont);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin: 0 0 10px;
}

.welcomeTwo > p {
  font-family: var(--baseFont);
  color: white;
  font-size: 18px;
  text-align: center;
  letter-spacing: 3px;
  margin: 0 0 30px;
}

.logBoxContainer {
  display: grid;
  justify-items: center;
  padding-top: 5px;
}

.xMark > p {
  font-family: var(--baseFont);
  font-size: 30px;
  font-weight: 600;
  color: var(--colorSecondaryBase);
  text-transform: uppercase;
  letter-spacing: 3px;
  text-align: center;
  margin: 0;
  padding-top: 20px;
}

.xMarksTwo > p {
  font-family: var(--baseFont);
  color: white;
  font-size: 20px;
  text-align: center;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin: 0;
}

@media only screen and (max-width: 1200px) {

  .container {
    background: var(--colorSecondarySuperDark) url('/assets/images/loginTablet.jpg') no-repeat center / cover;
  }
}

@media only screen and (max-width: 700px) {

  .welcome > p {
    font-size: 25px;
  }
  
  .welcomeTwo > p {
    font-size: 14px;
  }
  

  .xMark > p {
    font-size: 25px;
  }
  
  .xMarksTwo > p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 500px) {

  .welcome > p {
    font-size: 20px;
  }
  
  .welcomeTwo > p {
    font-size: 11px;
  }
  

  .xMark > p {
    font-size: 20px;
  }
  
  .xMarksTwo > p {
    font-size: 11px;
  }
  
}

@media only screen and (max-width: 350px) {

  .container {
    background: var(--colorSecondarySuperDark) url('/assets/images/LOGIN_BACKGROUND_Nexus_Mobile.jpg') no-repeat left / cover;
  }
}
