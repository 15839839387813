.container {
  font-size: 14px;
}
.totalLine {
  text-decoration: overline;
  text-decoration-color: black;
}
.container:nth-child(odd){
  background-color: rgb(218, 218, 218);
  margin-bottom: 5px;
  padding: 5px;
}
.container:nth-child(even){
  background-color: white;
  margin-bottom: 5px;
  padding: 5px;
}

@media only screen and (min-width: 550px) {
  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    font-size: 14px;
  }
}


