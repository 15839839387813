.container {
  padding: 10px;
  display: grid;
  grid-template-columns: 6fr;
  grid-column-gap: 20px;
  background-color: white;
}

.poWrapper {
  font-size: 16px;
}

.detailsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
  color: black;
}

.summaryWrap {
  display: flex;
  flex-direction: column;
}

.startDetails,
.endDetails {
  font-size: 12px;
}

.buttonWrapper {
  display: grid;
  align-items: center;
}

.detailsLabel {
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
  color: black;
  @media only screen and (max-width: 550px) {
    width: 100%;
  }
}

.notificationIcon svg * {
  color: var(--colorSecondaryLight);
}

@media screen and (max-width: 1100px) {

  .container {
   border-bottom:1px solid gray;
  }

  .detailsWrap {
   grid-column-gap: 0px;
  }

  .poWrapper {
    margin-bottom: 7px;
  }
}

@media screen and (max-width: 875px) {

 .detailsWrap {
  grid-template-columns: 1fr;
 }

}
