.container {
  max-width: 1200px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
}

.heading {
  margin: 0;
  margin: 0 20px 10px 0;
  color: black;
  border-right: 1px solid #ccc;
  padding-right: 20px;
  line-height: 1.2;
}

.light > .heading {
  color: white
}

.headingContainer {
  display: inline-flex;
  align-items: center;
  padding: 25px 0 15px;
}

.link {
  border: 1px solid var(--colorSecondaryBase);
  border-radius: 20px;
  color: var(--colorSecondaryBase);
  padding: 5px 20px 5px 10px;
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  position: relative;
}

.light > .link {
  border: 1px solid var(--colorPrimaryBase);
  color: var(--colorPrimaryBase);
}

.link:after {
  content: '›';
  font-size: 21px;
  margin-left: 5px;
  line-height: 0px;
  position: absolute;
  top: 8px;
  right: 9px;
}

.link:hover {
  background: var(--colorFormInputFontColor);
  color: var(--colorLightWhite);
}

@media only screen and (max-width: 700px) {
  .headingContainer {
    display: block;
  }

  .heading {
    border-right: none;
    font-size: 26px;
  }

  .link {
    float: right;
  }
}