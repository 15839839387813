.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.topContainer {
  flex-shrink: 0;
  display: grid;
  grid-row-gap: 10px;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 20px;
  grid-template-areas: 
  "contact "
  "terms "
  "chart";
}

.contact {
  grid-area: contact;
}

.terms {
  grid-area: terms;
}

.chart {
  grid-area: chart;
}

.view {
  width: 100%;
}

@media only screen and (max-width: 500px) {
  .chart {
    display: none;
  }
}

@media only screen and (min-width: 600px) {
  .topContainer {
    align-content: start;	
    grid-gap: 15px;
    grid-template-areas: 
      "contact contact"
      "terms terms"
      "chart chart";
    
    &.noChart {
      grid-template-areas: 
      "contact contact"
      "terms terms";
    }
  } 
}

@media only screen and (min-width: 800px) {
  .topContainer {
    grid-template-columns: 1fr 1fr 1.5fr;
    grid-template-areas: 
      "contact contact chart"
      "terms terms chart";

      &.noChart {
        grid-template-areas: 
        "contact contact contact"
        "terms terms terms";
      }
  }
}  
  