.container {
  font-size: 14px;
  overflow-x: scroll;

  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6px 10px;
    margin: 5px 0 15px;
  }
}

@media only screen and (max-width: 600px) {
  .container > .row {
    grid-template-columns: 1fr;
  }
}