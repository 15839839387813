.disclaimerContainer {
  display:flex;
  flex-direction: column;
  align-items: center;
  height:100%;
}

.disclaimerContainer .header {
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
}

.buttonContainer {
  display: flex;
  justify-content: space-between;
  width:100%
}

.continue {
  background-color: var(--colorSecondaryBase);
  margin-bottom:10px;
}
.continue:hover {
  background-color: var(--colorSecondaryDark);
}

.continueDisabled {
  background-color: var(--colorPrimaryFaded);
  margin-bottom:10px;
}

p {
  font: var(--baseFont);
  line-height: 2;
  margin-bottom:40px;
}

.customerDropdown {
  display:flex;
  align-items:center;
}

.customerDropdown h3 {
  margin:0px;
  margin-right:10px;
}

@media only screen and (max-width: 980px) {
  .buttonContainer {
    flex-direction: column-reverse;
  }

  .buttonContainer :nth-child(2) { 
    order:1;
    margin-left:5px;
    color:green;
  }

  .disclaimerContainer .buttonContainer span a {
    margin-left: 0px;
  }

  .customerDropdown {
    width:100%;
    justify-content: space-around;
    margin-bottom:40px;
  }
}

@media only screen and (max-width: 450px) {
  .customerDropdown h3 {
    display: none;
  }
}