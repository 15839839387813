.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px 0 20px;
  flex: 1;
}

strong {
  color: black;
}
