.container {
  background-color: transparent;
  outline: 0;
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 2px;
  border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: #cccccc;
  border-left-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  transition: transform .3s, border-bottom-color .3s;
  transform: rotateZ(0deg);//makes transitions work by setting default
  transform: rotateX(0deg);//makes transitions work by setting default
  
  &.right {
    transform: rotateZ(90deg);
  }

  &.left {
    transform: rotateZ(270deg);
  }
  
  &.down {
    transform: rotateX(180deg);
  }

  &.active {
    border-bottom-color: var(--colorPrimaryBase);
  }
}