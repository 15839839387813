$firstBreakpoint: 1000px;
$secondBreakpoint: 1800px;

.grid {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
  
  .map {
    display:grid;
    min-height: 400px;

    //hack for the google maps library. Should not nest this deep
    >div {
      display:grid;

      >div{
        position: relative;
      }
    }
  }

  .positionInitial {
    position: relative;
  }

  .addDocument {
    position: absolute;
    right: 5px;
    top: 0;
    font-size: 20px;
    background-color: transparent;
    color: white;
  }

  .addDocument:hover {
    color: #e3761c;
    cursor: pointer;
  }
  
  @media only screen and (min-width: $firstBreakpoint) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 
    "details details map map"
    "accessorialCharges accessorialCharges b c"
    "notes notes notes notes"
    "macropoint macropoint macropoint macropoint"
    "checkCalls checkCalls checkCalls checkCalls"
    "origin origin origin origin"
    "drops drops drops drops";
  }
}


@media only screen and (min-width: $firstBreakpoint) {
  
  .details {
    grid-area: details;
  }
  
  .map {
    grid-area: map;
  }

  .accessorialCharges {
    grid-area: accessorialCharges;
  }


  .brokerNotes {
    grid-area: notes;
  }
  
  .macropoint {
    grid-area: macropoint;
  }
  .checkCalls {
    grid-area: checkCalls;
  }

  .origin {
    grid-area: origin;
  }

  .drops {
    grid-area: drops;
  }
}

.scroll {
  max-height: 110px;
  overflow-y: scroll;
}

