.toggleSwitchContainer {
  margin-bottom: 10px;
}

.reactSwitch {
  vertical-align: middle;
  margin-left: 4px;
}

.switchText {
  display: inline;
  margin-left: 10px;
  font-size: 16px;
  cursor: pointer;
  vertical-align: top;
}