.textBox {
  resize: none;
}

.textBoxInvalid {
  border-style: solid;
  border-color: var(--colorDanger)
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  font-size: 14px;
}
::-moz-placeholder { /* Firefox 19+ */
  font-size: 14px;
}
:-ms-input-placeholder { /* IE 10+ */
  font-size: 14px;
}
:-moz-placeholder { /* Firefox 18- */
  font-size: 14px;
}