.container {
  background-color: var(--colorPrimaryBase);
  position: relative;
  z-index: 1;
}

.innerWrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  padding-left: 20px;
  color: white;
  font-size: 24px;
}

.linkButton {
  padding: 0;
  color: white;
  margin-left: 20px;
}

.linksWrapper {
  display: flex;
}