.container {
  padding: 10px;
  background-color: white;
}

.linesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 6px;
  margin-bottom: 10px;
}

@media screen and (min-width: 800px) {
  .linesWrapper {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 90px;
}

.updateButton {
  padding-top: 5px;
}