.container{
  display: block;
  >li {
    list-style: none;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  >li::before{
    content: "\25FE";
    color: #214E6B;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: 1em;
    list-style: square;
  }
  >li>span{
    font-weight: bold;
  }
}
