.container {
  padding: 5px 10px;
  overflow: auto;
  min-height: 100%;
  text-align: center;
}

.headerWrapper {
  justify-content: space-between;

  >h2 {
    margin: 0;
    font-size: 20px;
    color: black;
  }
}

.button {
  background: var(--colorSecondaryBase);
  margin: 10px 0;
  position: absolute;
  bottom: 20px;
  left: 25%;
  width: 50%;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
}

.moreInfo {
  margin-top: 10px;
}
