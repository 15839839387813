.container {
  padding: 10px;
  display: grid;
  grid-template-columns: 6fr 1fr;
  grid-column-gap: 20px;
  background-color: white;
}

.poWrapper {
  font-size: 16px;
}

.detailsWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 20px;
}

.summaryWrap {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-column-gap: 20px;
}

.startDetails,
.endDetails {
  font-size: 12px;
}

.buttonWrapper {
  display: grid;
  align-items: center;
}

.detailsLabel {
  font-weight: bold;
  margin: 0;
  text-transform: uppercase;
}

.notificationIcon svg * {
  color: var(--colorSecondaryLight);
}

@media screen and (max-width: 720px) {
  .summaryWrap {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 20px;
  }

  .poWrapper {
    margin-bottom: 7px;
  }
}
