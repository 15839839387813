.container {
  font-size: 14px;
  overflow-x: scroll;
  
  .row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 6px 10px;
    padding-left: 20px;
    position: relative;
    margin: 5px 0 15px;
  }
}

.identifier {
  position: absolute;
  font-weight: bold;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 600px) {
  .container > .row {
    grid-template-columns: 1fr;
  }
}