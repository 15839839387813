.button {
  width: 100%;
  margin-top: 5px
}

.button_update {
  width: 100%;
  margin-top: 5px;
  background-color: var(--colorSecondaryBase);
}

.button_update:hover {
  background-color: var(--colorSecondaryLight);
}