.modalWrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.modal {
  background: var(--colorLightWhite);
  width: 400px;
  height: 280px;
  position: absolute;
  left: calc(50% - 200px);
  top: calc(50% - 100px);
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 1px 1px 5px #ccc;
  z-index:100;
}
