.errorText {
    color: rgb(255, 0, 0);
    margin-bottom: 0;
}

.successText {
    color: rgb(0, 155, 0);
    margin-bottom: 0;
}

.submissionButton {
    cursor: pointer;
    position: absolute;
    right: 20px;
    bottom: 10px;
}

.submissionButtonDisabled {
    cursor: not-allowed;
    position: absolute;
    right: 20px;
    bottom: 10px;
    background-color: #999999
}

.selectFile {
    cursor: pointer;
    position: absolute;
    left: 10px;
    bottom: 10px;
}

.hoverBorder {
    outline: none;
    transition: border .24s ease-in-out;
    border-width: 5px;
    border-radius: 5px;
    border-color: #0099ff17;
    border-style: dashed;
    height: calc(50% - 20px);
    position: relative;
}

.fileContainer {
    height: calc(50% - 20px);
    overflow: auto;
}

.hoverBorder:hover {
    border-color: #0099ff;
}

.documentContainer {
text-align: left;
padding-left: 20px;
padding-bottom: 20px;
}

.documentInfo {
    margin: 0;
}

.unsubmit {
    background-color: transparent;
    font-size: 20px;
    padding: 0;
    padding-left: 10px;
}

.unsubmit > span {
    color: black;
}

span:hover {
    color: #e3761c;
    cursor: pointer;
}