$bannerHeight: 80px;
$triangleWidth: 50px;
%localFont {
  font-family: var(--secondaryFont);
  font-weight: 600;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.banner {
  height: $bannerHeight;
  display: flex;
  justify-content: space-between;
  background-color: gray;
}

.titleWrapper {
  background-color: var(--colorPrimaryBase);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.title {
  color: white;
  text-transform: uppercase;
  font-style: italic;
  letter-spacing: 2px;
  font-size: 40px;
  line-height: 1;
  margin-right: 30px;
  @extend %localFont;
  white-space: nowrap;
}

.subTitle {
  color: white;
  text-transform: none;
  font-weight: 200;
  font-size: 20px;
  line-height: 1;
  margin-left: 15px;
  @extend %localFont;
  white-space: nowrap;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    height: 40px;
    fill: white;
  }
}

.heroWrapper {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    height: 0;
    width: 0;
    border-bottom: $bannerHeight solid transparent;
    border-right: 0px solid transparent;
    border-left: $triangleWidth solid var(--colorPrimaryBase);
    position: absolute;
    top: 0;
    left: 0;
  }

  &:after {
    content: '';
    height: 0;
    width: 0;
    border-top: $bannerHeight solid transparent;
    border-left: 0px solid transparent;
    border-right: $triangleWidth solid var(--colorSecondaryLight);
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.totalWrapper {
  background-color: var(--colorSecondaryLight);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px 10px 0;
}

.total {
  color: white;
  font-size: 40px;
  line-height: 1;
  @extend %localFont;

  >span {
    font-size: 30px;
    line-height: 1;
    margin-left: 10px;
    color: white;
  }
}

.dataViewWrapper {
  border-bottom: 1px solid var(--colorPrimaryBase);
  border-left: 1px solid var(--colorPrimaryBase);
  border-right: 1px solid var(--colorPrimaryBase);
}

.navWrapper {
  padding-top: 20px;
}

@media screen and (max-width: 620px) {
  .title {
    font-size: 20px;
  }

  .total {
    font-size: 20px;
  
    >span {
      font-size: 18px;
    }
  }

}

@media screen and (max-width: 450px) {
  .iconWrapper {
    display: none;
  }

  .title {
    margin-right: 0;
    font-size: 16px;
  }

  .titleWrapper {
    padding-right: 0;
  }
}

.noPermissionText {
  font-size: 18px;
  text-align: center;
  padding: 10px 10px 10px 0;
}