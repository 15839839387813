.footerMain {
  width: 100%;
  background: url('/assets/images/FOOTER_BACKGROUND_Nexus.jpg') no-repeat center top / cover;
  position: relative;
  padding: 30px 0 0;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  display: flex;
}

.nexusLogo {
  width: 250px;
  height: auto;
}

.footerBrand {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  margin: auto 25% auto auto;
  max-width: 400px;
  padding-bottom: 30px;
}

.tagline {
  color: white;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
  margin: 0;
}

.contact {
  flex: 1;
  margin: auto;
  text-align: left;
  color: white;

  h3 {
    color: white;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: bold;
    margin: 0 0 10px;
  }

  p {
    color: white;
    margin: 0;
  }

  a {
    color: white;
    text-decoration: none;
    font-weight: bold;

    img {
      width: 40px;
      height: auto;
    }
  }
}

.social {
  margin: 10px 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px 0 0;
    padding: 0;
  }

}

@media only screen and (max-width: 1200px) {
  .container {
    margin: 0 20px;
  }
}

@media only screen and (max-width: 1160px) {
  .footerMain {
    background: url('/assets/images/FOOTER_BACKGROUND_Nexus_Tablet.jpg') no-repeat center top / cover;
  }
}

@media only screen and (max-width: 700px) {
  .footerMain {
    background: url('/assets/images/FOOTER_BACKGROUND_Nexus_Mobile.jpg') no-repeat center top / cover;
  }

  .footerBrand {
    margin: auto;
    min-width: 100%;
    max-width: 100%;
  }

  .container {
    flex-wrap:wrap;
    padding-bottom: 30px;
  }

  .tagline {
    font-size: 24px;
  }

}