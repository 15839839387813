$breakpoint: 1000px;

.container {
  display: flex;
}

.link {
  padding: 0;
  color: white;
  transition: color .3s;
  text-decoration: none;
  text-align: center;
  line-height: 16px;
  font-size: 16px;
  min-width: 150px;
  display: block;
  &:hover {
    color: var(--colorSecondaryBase);
  }

  @media only screen and (max-width: $breakpoint) {
    margin-left: 20px;
    margin-bottom: 10px;
  }
}

.linksWrapper {
  position: absolute;
  top: 60px;
  right: 0;
  flex-direction: column;
  background-color: var(--colorPrimaryBase);
  width: 100%;
  display: none;
  align-items: center;

  &.isOpen {
    display: flex;
  }


  @media only screen and (min-width: $breakpoint) {
    display: flex;
    position: static;
    flex-direction: row;
    width: 100%;
    margin-bottom: 0;
  }
}

.menuIcon {
  width: 24px;
  position: relative;
  display: block;
  cursor: pointer;
  margin-top: 8px;
}

.top,
.middle,
.bottom {
  height: 3px;
  width: 100%;
  margin-bottom: 5px;
  background-color:white;
  pointer-events: none;
}

.bottom {
  margin-bottom: 0;
}

@media only screen and (min-width: $breakpoint) {
  .menuIcon {
    display: none;
  }
}
